import React from 'react';

const FormattedDate = ({ date, separator = '.' }) => {
    const OStartDate = new Date(date);

    return (
        <>{`${OStartDate.getDate()}${separator}${
            OStartDate.getMonth() + 1
        }${separator}${OStartDate.getFullYear()}`}</>
    );
};

export default FormattedDate;
