import React from 'react';
import styled from 'styled-components';
import { PlayCircleFilled } from '@ant-design/icons';
import Image from 'components/Image';

interface ContainerProps {
    $maxWidth?: number;
}

const Container = styled.div<ContainerProps>`
    position: relative;
`;

const VideoIcon = styled(PlayCircleFilled).attrs({
    size: 92,
    alt: 'play-overlay-button'
})`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    color: white !important;
    text-shadow: black 0px 0px 10px;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
`;

interface Props {
    image: string;
    height?: number;
    width?: number;
    playIcon?: boolean;
}

const VideoImage = ({ image, height = 153, width = 272, playIcon = true }: Props): JSX.Element => {
    return (
        <Container $maxWidth={width}>
            <Image
                type="index"
                src={image}
                width={width}
                height={height}
                alt="Zdjęcie otwierające wideo"
                style={{ objectFit: 'contain', maxWidth: '100%', height: 'auto' }}
            />
            {playIcon && (
                <Overlay>
                    <VideoIcon />
                </Overlay>
            )}
        </Container>
    );
};

export default VideoImage;
