import React from 'react';
import { Col, Row } from 'antd';
import Link from 'next/link';
import styled from 'styled-components';
import Image from 'next/image';
import { Title } from 'components/ArticleCard';
import SeeMoreImage from 'public/static/images/more_placeholder.png';

interface Props {
    url: string;
    noImage?: boolean;
    width?: number;
    height?: number;
}

const SeeMoreTitle = styled(Title)``;

const StyledNoImageTitle = styled(SeeMoreTitle)`
    @media (min-width: 992px) {
        padding-top: 25px;
    }
    a {
        color: ${({ theme }) => theme.colors.primary};
    }
`;

const SeeMoreCard = ({ url, noImage = false, height = 173, width = 272 }: Props): JSX.Element => {
    if (noImage) {
        return (
            <Row gutter={16}>
                <Col xs={14} sm={16} md={24}>
                    <StyledNoImageTitle>
                        <Link href={url}>Zobacz więcej...</Link>
                    </StyledNoImageTitle>
                </Col>
            </Row>
        );
    }

    return (
        <Row gutter={16}>
            <Col xs={10} sm={8} md={24}>
                <Link href={url}>
                    <Image height={height} width={width} alt="Zobacz więcej" src={SeeMoreImage} />
                </Link>
            </Col>
            <Col xs={14} sm={16} md={24}>
                <SeeMoreTitle>
                    <Link href={url}>Zobacz więcej...</Link>
                </SeeMoreTitle>
            </Col>
        </Row>
    );
};

export default SeeMoreCard;
