import Image, { ImageProps } from 'next/image';
import Placeholder from 'public/static/images/image-coming-soon-placeholder.png';
import resolveImagePath from 'lib/resolveImagePath';
import { css, styled } from 'styled-components';

interface Props extends ImageProps {
    type?: string;
    showEmptyImage?: boolean;
    priority?: boolean | undefined;
}

const ImageStyled = styled(Image)<ImageProps>`
    max-width: 100%;
    height: auto;
    ${({ fill }) =>
        fill &&
        css`
            object-fit: contain;
        `}
`;

const MyImage = ({ type, showEmptyImage = true, ...restProps }: Props): JSX.Element => {
    const myLoader = ({ src, width }) => {
        if (src.indexOf('http://') === 0 || src.indexOf('https://') === 0) {
            return src;
        }
        let filter = 'original';
        switch (type) {
            case 'billboard':
                filter = 'billboard';
                if (width > 600) {
                    filter = 'billboard_big';
                }
                break;
            case 'avatar':
                filter = 'photo_small';
                if (width > 200) {
                    filter = 'author_big';
                }
                break;
            case 'logo':
                filter = 'logo_small';
                if (width > 200) {
                    filter = 'logo_big';
                }
                break;
            case 'white-paper':
                filter = 'white_paper_small';
                if (width > 250) {
                    filter = 'white_paper_big';
                }
                break;
            case 'issue':
                filter = 'magazine_small';
                if (width > 250 && width < 370) {
                    filter = 'magazine_medium';
                }
                if (width > 370) {
                    filter = 'magazine_big';
                }
                break;
            case 'index':
                filter = 'index_small';
                if (width > 384 && width < 600) {
                    filter = 'index_medium';
                }
                if (width > 600) {
                    filter = 'index_big';
                }
                break;
            case 'opening':
                filter = 'opening_small';
                if (width > 384 && width < 600) {
                    filter = 'opening_medium';
                }
                if (width > 800) {
                    filter = 'opening_big';
                }
                break;

            case 'newsletter':
                filter = 'newsletter_thumb';
                break;
            case 'newsletter_video':
                filter = 'newsletter_video_thumb';
                break;
            default:
                if (width < 300) {
                    filter = 'responsive_small';
                }
                if (width > 300 && width < 600) {
                    filter = 'responsive_medium';
                }
        }

        return resolveImagePath(src, filter);
    };

    if (!restProps.src && !showEmptyImage) {
        return null;
    }

    if (!restProps.src) {
        return <ImageStyled {...restProps} src={Placeholder} fill={restProps.fill} />;
    }
    try {
        return (
            <ImageStyled
                loader={myLoader}
                {...restProps}
                unoptimized={false}
                fill={restProps.fill}
            />
        );
    } catch (error) {
        return <ImageStyled {...restProps} src={Placeholder} fill={restProps.fill} />;
    }
};

export default MyImage;
