import styled from 'styled-components';
import { merriweather } from 'styles/GlobalStyle';

interface Props {
    noImages?: boolean;
}

const Date = styled.h3<Props>`
    font-family: ${merriweather.style.fontFamily};
    font-size: 14px;
    line-height: 14px;
    font-weight: 300;
    margin-bottom: 0;
    @media (max-width: 768px) {
        padding-top: 0;
        font-size: 12px;
        line-height: 18px;
    }
`;

export default Date;
