import { Col } from 'antd';
import styled from 'styled-components';

const Content = styled(Col)`
    @media (min-width: 1164px) {
        width: 66%;
        width: calc(100% - 316px);
    }
    @media (max-width: 1164px) {
        width: 66%;
    }
    @media (max-width: 992px) {
        width: 100%;
    }
`;

export default Content;
