import React from 'react';
import { Col } from 'antd';
import Link from 'next/link';
import parseCategoryUrl from 'lib/parseCategoryUrl';
import Card from './Card';
import GalleryImage from 'components/GalleryImage';
import Section from 'components/Section';
import { Category, Title } from 'components/ArticleCard';

interface Props {
    images: string[];
    title: string;
    category?: Category;
    slug: string;
}

const GalleryCard = ({ images, title, slug, category }: Props): JSX.Element => {
    return (
        <Card
            bordered={false}
            cover={
                <Link href={`/galerie/${slug}`}>
                    <GalleryImage image={images[0]} imagesInGallery={images.length} />
                </Link>
            }
            style={{ boxShadow: 'none', borderRadius: 0 }}
        >
            <Section $marginTop={5}>
                <Col span={24}>
                    {category && (
                        <Category>
                            <Link href={parseCategoryUrl(category)}>{category.title}</Link>
                        </Category>
                    )}
                    <Title>
                        <Link href={`/galerie/${slug}`}>{title}</Link>
                    </Title>
                </Col>
            </Section>
        </Card>
    );
};

export default GalleryCard;
