import styled from 'styled-components';

interface Props {}

const Category = styled.h3<Props>`
    font-size: 14px;
    line-height: 26px;
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 0;
    :hover {
        color: ${(props) => props.color || props.theme.colors.darkGrey};
    }
    a {
        :hover {
        }
        color: ${(props) => props.color || props.theme.colors.darkGrey};
    }
    @media (max-width: 768px) {
        padding-top: 0;
        font-size: 12px;
        line-height: 18px;
    }
`;

export default Category;
