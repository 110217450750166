import React from 'react';
import styled from 'styled-components';
import { CameraOutlined } from '@ant-design/icons';
import Image from 'components/Image';

interface ContainerProps {
    $maxWidth?: number | string;
}

const Container = styled.div<ContainerProps>`
    max-width: ${({ $maxWidth }) => $maxWidth || '300px'};
    position: relative;
`;

const CountWrapper = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    color: #ffffff;
    background: rgba(126, 126, 126, 0.7);
    padding: 6px 10px 4px 7px;
`;

interface Props {
    image: string;
    imagesInGallery?: number;
    height?: number;
    width?: number;
}

const GalleryImage = ({
    image,
    imagesInGallery,
    height = 153,
    width = 272
}: Props): JSX.Element => {
    return (
        <Container $maxWidth={width}>
            <Image
                type="index"
                src={image}
                width={width}
                height={height}
                alt="gallery"
                style={{ objectFit: 'contain', maxWidth: '100%', height: 'auto' }}
            />
            {imagesInGallery && (
                <CountWrapper>
                    <CameraOutlined /> {imagesInGallery}
                </CountWrapper>
            )}
        </Container>
    );
};

export default GalleryImage;
