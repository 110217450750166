import React from 'react';
import Link from 'next/link';
import Section from 'components/Section';
import { Col } from 'antd';
import parseCategoryUrl from 'lib/parseCategoryUrl';
import Card from './Card';
import { Category, Title } from 'components/ArticleCard';
import VideoImage from '../VideoImage';

interface Props {
    video: Video;
}

const VideoCard = ({ video }: Props): JSX.Element => {
    return (
        <Card
            bordered={false}
            cover={
                <Link href={`/wideo/${video.seoSlug}`}>
                    <VideoImage image={video.openingImage} />
                </Link>
            }
            style={{ boxShadow: 'none', borderRadius: 0 }}
        >
            <Section $marginTop={5}>
                <Col span={24}>
                    {video.category && (
                        <Category>
                            <Link href={parseCategoryUrl(video.category)}>
                                {video.category.title}
                            </Link>
                        </Category>
                    )}
                    <Title>
                        <Link href={`/wideo/${video.seoSlug}`}>{video.title}</Link>
                    </Title>
                </Col>
            </Section>
        </Card>
    );
};

export default VideoCard;
