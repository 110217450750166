import styled from 'styled-components';
import { Card } from 'antd';

interface CardProps {
    readonly large?: boolean;
}

const CardStyled = styled(Card)<CardProps>`
    .ant-card-body {
        padding: 0;
    }
    img {
        vertical-align: middle;
        width: 100%;
        height: auto;
        margin: auto;
        border-radius: 0 !important;
    }
`;

export default CardStyled;
