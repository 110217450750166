import React from 'react';
import { Col, Row } from 'antd';
import Link from 'next/link';
import Image from 'components/Image';
import parseCategoryUrl from 'lib/parseCategoryUrl';
import { Title, Text, Category, SponsoredText } from 'components/Card';

interface Props {
    $article: Article;
    size: {
        span?: string | number;
        xs?: string | number;
        sm?: string | number;
        md?: string | number;
        lg?: string | number;
        xl?: string | number;
        xxl?: string | number;
    };
    large?: boolean;
    noImages?: boolean;
    noTeaser?: boolean;
    companyAsCategory?: Company;
}

const ArticleCard = ({
    $article,
    large = false,
    size,
    noImages = false,
    noTeaser = false,
    companyAsCategory = undefined
}: Props): JSX.Element => {
    const { category, seoSlug, title, teaser, openingImage, sponsored = false } = $article;
    if (noImages) {
        return (
            <Col {...size}>
                {category && (
                    <Category>
                        {companyAsCategory ? (
                            <Link href={`/katalog-firm/${companyAsCategory.seoSlug}`}>
                                {companyAsCategory.name}
                            </Link>
                        ) : (
                            <Link href={parseCategoryUrl(category)}>{category.title}</Link>
                        )}
                    </Category>
                )}
                <Title>
                    <Link href={`/artykuly/${seoSlug}`}>{title}</Link>
                </Title>
                {noTeaser || (
                    <Text>
                        <Link href={`/artykuly/${seoSlug}`}>
                            <div dangerouslySetInnerHTML={{ __html: teaser }} />
                        </Link>
                    </Text>
                )}
                {sponsored && <SponsoredText>Artykuł sponsorowany</SponsoredText>}
            </Col>
        );
    }
    return (
        <Col {...size}>
            <Row gutter={16}>
                <Col xs={10} sm={8} md={24}>
                    {openingImage && (
                        <Link href={`/artykuly/${seoSlug}`}>
                            <Image
                                type="index"
                                height={large ? 320 : 178}
                                width={large ? 569 : 316}
                                alt={title}
                                src={openingImage}
                                style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                            />
                        </Link>
                    )}
                </Col>
                <Col xs={14} sm={16} md={24}>
                    {category && (
                        <Category>
                            {companyAsCategory ? (
                                <Link href={`/katalog-firm/${companyAsCategory.seoSlug}`}>
                                    {companyAsCategory.name}
                                </Link>
                            ) : (
                                <Link href={parseCategoryUrl(category)}>{category.title}</Link>
                            )}
                        </Category>
                    )}
                    <Title>
                        <Link href={`/artykuly/${seoSlug}`}>{title}</Link>
                    </Title>
                    {noTeaser || (
                        <Link href={`/artykuly/${seoSlug}`}>
                            <Text dangerouslySetInnerHTML={{ __html: teaser }} />
                        </Link>
                    )}
                    {sponsored && <SponsoredText>Artykuł sponsorowany</SponsoredText>}
                </Col>
            </Row>
        </Col>
    );
};

export default ArticleCard;

export { ArticleCard, Category, Title, Text, SponsoredText };
