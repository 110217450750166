import React from 'react';
import { Col, Row } from 'antd';
import Link from 'next/link';
import Image from 'components/Image';
import parseCategoryUrl from 'lib/parseCategoryUrl';
import VideoImage from 'components/VideoImage';
import GalleryImage from 'components/GalleryImage';
import FormattedDate from 'components/FormattedDate';
import Category from './Category';
import Title from './Title';
import Text from './Text';
import SponsoredText from './SponsoredText';
import Date from './Date';

interface Props {
    $item: {
        title: string;
        seoSlug: string;
        image: string;
        category: Category;
        description: string;
        phrase: string;
        type: 'article' | 'video' | 'gallery' | 'event';
        additionalInfo?: string;
        dateDisplayed: string;
    };
}

const ItemImage = ({ type, image, additinalInfo, alt }) => {
    switch (type) {
        case 'video':
            return <VideoImage image={image} />;
        case 'gallery':
            return <GalleryImage image={image} imagesInGallery={additinalInfo} />;
        default:
            return <Image type="index" height={153} width={272} src={image} alt={alt} />;
    }
};

const Card = ({ $item }: Props): JSX.Element => {
    const getItemLink = (type: string, seoSlug: string) => {
        switch (type) {
            case 'article':
                return `/artykuly/${seoSlug}`;
            case 'video':
                return `/wideo/${seoSlug}`;
            case 'gallery':
                return `/galerie/${seoSlug}`;
            case 'event':
                return `/wydarzenia/${seoSlug}`;
            case 'company':
                return `/katalog-firm/${seoSlug}`;
        }
    };

    return (
        <Row gutter={16}>
            <Col span={8}>
                <Link href={getItemLink($item.type, $item.seoSlug)}>
                    <ItemImage
                        alt={$item.title}
                        type={$item.type}
                        image={$item.image}
                        additinalInfo={$item.additionalInfo}
                    />
                </Link>
            </Col>
            <Col span={16}>
                <Row>
                    <Col span={24}>
                        <Row justify="space-between">
                            <Col span="auto">
                                {$item.category && (
                                    <Category>
                                        {$item.category.seoSlug && (
                                            <Link href={parseCategoryUrl($item.category)}>
                                                {$item.category.title}
                                            </Link>
                                        )}
                                    </Category>
                                )}
                            </Col>
                            <Col>
                                <Date>
                                    <FormattedDate date={$item.dateDisplayed} />
                                </Date>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Title>
                            <Link href={getItemLink($item.type, $item.seoSlug)}>{$item.title}</Link>
                        </Title>
                    </Col>
                    <Col span={24}>
                        <Text>
                            <Link href={getItemLink($item.type, $item.seoSlug)}>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: $item.description
                                    }}
                                />
                            </Link>
                        </Text>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default Card;

export { Card, Category, Title, Text, SponsoredText };
