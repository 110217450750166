import styled from 'styled-components';

type Props = {
    color?: string;
};

export const Text = styled.div<Props>`
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    font-weight: 300;
    line-height: 1.57;
    word-wrap: break-word;
    -webkit-line-clamp: 4; /* number of lines to show */
    -webkit-box-orient: vertical;
    color: ${(props) => props.color || props.theme.colors.derkerGrey};
    @media (max-width: 992px) {
        display: none;
    }
    a {
        :hover {
        }
        color: ${(props) => props.color || props.theme.colors.derkerGrey};
    }
`;

export default Text;
