import styled from 'styled-components';

const Title = styled.h2`
    margin-top: 0;
    margin-bottom: 0;
    font-size: 1.2rem;
    line-height: 26px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    word-wrap: break-word;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
    a {
        :hover {
        }
        color: ${(props) => props.color || props.theme.colors.greyishBrownTwo};
    }

    @media (max-width: 768px) {
        font-size: 14px;
        line-height: 21px;
    }
`;

export default Title;
